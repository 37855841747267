:root {
  font-size: 10px;
}

* {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: "Roboto Condensed", "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

canvas {
  width: 100% !important;
}

.pac-container:after {
  /* Disclaimer: not needed to show 'powered by Google' if also a Google Map is shown */

  background-image: none !important;
  height: 0px;
}

/* custom scrollbar */
::-webkit-scrollbar {
  width: 12px;
}

::-webkit-scrollbar-track {
  background: #00000073;
}

::-webkit-scrollbar-thumb {
  background-color: #8997a9;
  border: 3px solid transparent;
  background-clip: content-box;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #778292;
}

::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.form-check-input:checked {
  background-color: #3699ff;
  border-color: #3699ff;
}

input[type="checkbox"]:checked {
  background-color: #3699ff;
  border-color: #3699ff;
}

/* css for React Date Time Picker below */

.react-datetime-picker {
  flex: 1;
}

.react-datetime-picker__wrapper {
  border: thin solid hsl(0, 0%, 80%) !important;
  border-radius: 4px;
  padding: 2px 8px;
  min-height: 3rem;
}

.icon path {
  stroke: #f05454;
}
/* hsl(0, 0%, 100%) */

/* used to override pale yellow color that chrome uses when user chooses an item from 
its autocomplete list */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
}

input:-webkit-autofill {
  -webkit-text-fill-color: #fff;
}

input:-webkit-autofill:focus {
  -webkit-text-fill-color: #fff;
}
